import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactBanner from "../components/ContactBanner";
import GoogleMap from "../components/GoogleMap"
import { graphql } from "gatsby";

class ContactUs extends Component {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <Layout
                headerElement={<ContactBanner />}
                scrollStepInPx={50}
                delayInMs={2}
            >
                <SEO title="Contact Us" />
                <section>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <h3 className="text-uppercase">Get In Touch!</h3>
                                <p style={{fontSize: '1.25rem'}}>Thank you for your interest and for visiting our website. For more information and inquiries, please contact us below.</p>
                                <div className="m-t-30">
                                    <form name="contact-form" method="POST" data-netlify="true">
                                        <input type="hidden" name="form-name" value="contact-form" />
                                        <div className="row">
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" name="name" required className="form-control" placeholder="Enter your Full Name" />
                                            </div>
                                            <div className="form-group col-md-6 mb-3">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" name="email" required className="form-control" placeholder="Enter your Email" />
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-3">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="subject">Your Subject</label>
                                                <input type="text" name="subject" required className="form-control" placeholder="Subject..." />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" required rows={5} className="form-control" placeholder="Enter your Message"></textarea>
                                        </div>
                                        <button type="submit" className="btn mt-3" ><i className="fa fa-paper-plane"></i>&nbsp;Send message</button>
                                    </form>
                                </div>
                                <div className="line"></div>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 p-0 mb-5">
                                        <address style={{fontSize: 16}}>
                                            <h4>ANSDPAD Office</h4><br />
                                            49 Wilfred Jackson Way<br />
                                            Cherry Brook, NS B2Z 1A8<br />
                                            <abbr title="Phone">Phone:</abbr> <span style={{marginLeft: 21}}>(902) 462-3723 (DPAD)</span><br />
                                            <abbr title="Phone">Toll Free:</abbr> <span style={{marginLeft: 4}}>1-833-330-3723 (DPAD)</span><br />
                                            <abbr title="Email">Email:</abbr> <span style={{marginLeft: 4}}>office@ansdpad.ca</span>
                                        </address>
                                    </div>
                                    <div className="col-lg-6 m-b-30 p-0">
                                        <h4>Check us out on our social media</h4>
                                        <div className="social-icons social-icons-light social-icons-colored-hover">
                                            <ul>
                                                <li className="social-facebook"><a href="https://www.facebook.com/African-Nova-Scotian-Decade-for-People-of-African-Descent-Coalition-103200967849187/" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/ansdpad" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                                <li className="social-instagram"><a href="https://instagram.com/ansdpad" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/company/ansdpad" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
            </Layout>
        );
    }
}

export default ContactUs
