import React, { Component, Fragment } from "react"

class ContactBanner extends Component {
  render() {
    return (
      <Fragment>
        <div id="slider" className="inspiro-slider dots-creative" style={{marginBottom: -300}}>
          <div className="slide kenburns" style={{  
            backgroundImage: "url(" + "https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/contact-banner.png" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '75%'
          }}>
              <div className="bg-overlay" style={{height: '75%'}}></div>
              <div className="container-wide">
                  <div className="container-fullscreen">
                      <div className="text-middle">
                          <div className="heading-text text-light col-lg-6 pt-5">
                              <h2 className="font-weight-600 pt-5"><span style={{color: '#FCB616'}}>Contact Us</span></h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContactBanner